import type { StepComponentSpec } from '../stepComponentSpec';
import type { CustomSelectValue, OptionsWithOther } from './additional';

export enum SelectMode {
  color = 'color',
  dropdown = 'dropdown',
  dropdownBasic = 'dropdown_basic',
  dropdownWithTabs = 'dropdown_with_tabs',
  miniButtonGroup = 'mini_button_group',
  wide = 'wide',
  buttonsWithCreate = 'buttons_with_create',
}

export enum SelectSource {
  carMake = 'car-make',
  carModel = 'car-model',
  carYear = 'car-year',
  colors = 'colors',
  usStates = 'us-states',
  usStateCities = 'us-state-cities',
  highwaysInState = 'us-state-highways',
  highwayDirections = 'us-state-highway-directions',
  highwayExits = 'us-state-highway-exits',
}

export interface SelectFields
  extends Partial<OptionsWithOther<CustomSelectValue>> {
  subtle_with_textfield?: boolean;
  no_scroll_into_view?: boolean;
  grid_by?: number;
  grid_by_desktop?: number;
  allow_other?: boolean;
  disable_create?: boolean;
  with_breathing_room?: boolean;
  source?: SelectSource | `${SelectSource}`;
  source_items?: string[];
  narrow?: boolean;
  label?: string;
  enableStatePickerV2?: boolean;
  select_mode_use_color_other?: boolean; // todo - remove afer qa
  placeholder?: string;
  existing_value?: CustomSelectValue;
  max?: number;
  min?: number;
  other_label?: string;
  filter?: {
    [key: string]: string;
  };
  required_no_selection_error_message?: string;
  min_query_length?: number;

  /**
   * Used in Sidekick to force display of large square buttons rather
   * than the typical long short buttons.
   */
  big_icon_buttons?: boolean;
  mode?: SelectMode | `${SelectMode}`;
}

type SelectStepComponentSpec = StepComponentSpec<SelectFields, 'select'>;

export default SelectStepComponentSpec;
